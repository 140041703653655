import React from 'react'
import { useState, useEffect } from 'react';
import Cargando from '../GeneralUI/Cargando'
import GlobalFunctions from '../../services/GlobalFunctions'
import TablaPrestamo from '../GeneralUI/TablaPrestamo'
import BotonPagos from '../GeneralUI/BotonPagos'
import FinancieraService from '../../services/FinancieraService'
import CasabonitaService from '../../services/CasaBonitaService';

const DCCasabonita = (params) => {
  const financieraService = new FinancieraService()
  const service = new CasabonitaService()
  const glob = new GlobalFunctions()
  const [datosCliente, setDatosCliente] = useState([])
  const [creditos, setCreditos] = useState([])
  const [cuotasMora, setCuotaMora] = useState(0)
  const [abonos, setAbonos] = useState([])
  const [articulos, setArticulos] = useState([])
  const [fechaSeleccionada, setFechaSeleccionada] = useState('')
  const [creditoSeleccionado, setCreditoSeleccionado] = useState([])
  const [abonosSeleccionado, setAbonosSeleccionado] = useState([])
  // variables para validar si cargar datos y evitar loops infinitos
  const [cargarDatosCliente, setCargarDatosCliente] = useState(true)
  //FLATAN LAS CUOTAS EN MORA
  const [cargarCuotasMora, setCargarCuotasMora] = useState(true)

  useEffect(() => {

    if (cargarCuotasMora && creditoSeleccionado.length > 0) {
      if (creditoSeleccionado[0].valor_cuotas > 0) {
        setCargarCuotasMora(false)
        financieraService.getCuotasMora(adaptarPeriodicidad(creditoSeleccionado[0].periodicidad), creditoSeleccionado[0].fecha_prest, creditoSeleccionado[0].tt_abonos, creditoSeleccionado[0].valor_cuotas, creditoSeleccionado[0].n_cuotas).then((data) => {
          setCuotaMora(data)
        })
      }
    }
  }, [creditoSeleccionado])

  useEffect(() => {
    if (glob.validarSesion()) {
      if (document.getElementById('btnGoLogin') !== null) {
        document.getElementById('btnGoLogin').click()
      }
    }
    if (cargarDatosCliente) {
      service.getDatosCliente().then((data) => {
        setCargarDatosCliente(false)
        setDatosCliente(data)
      })
      service.getAbonosCredito().then((data) => {
        setAbonos(data)
      })
      service.getDatosCredito().then((data) => {
        setCreditos(data)
        cargarArticulos()
        setFechaSeleccionada(data[0].fecha_prest)
      })
    }
  })

  function cargarArticulos() {
    service.getCompra().then((data) => {
      setArticulos(data)
    })
  }

  useEffect(() => {
    const listCredito = []
    listCredito.push(setCreditoByFecha())
    setCreditoSeleccionado(listCredito)
    setAbonosSeleccionado(setAbonosByFecha())
  }, [fechaSeleccionada, creditos])

  function adaptarPeriodicidad(per){
    let periodi='diario'
    if(per=='semanales'){
      periodi='semanal'
    }
    if(per=='quincenales'){
      periodi='quincenal'
    }
    if(per=='mensuales'){
      periodi='mensual'
    }
    return periodi
  }

  function setCreditoByFecha() {
    let creditoSelected = [];
    for (let i = 0; i < creditos.length; i++) {
      if (fechaSeleccionada === creditos[i].fecha_prest) {
        creditoSelected = creditos[i]
      }
    }
    return creditoSelected
  }

  function setAbonosByFecha() {
    const filter = abonos.filter(est => est.Cobro === fechaSeleccionada)
    return filter
  }

  function setArticulosByFecha(fecha) {
    let art = '';
    for (let i = 0; i < articulos.length; i++) {
      if (fecha === articulos[i].fecha) {
        art = articulos[i].listaProductos[0]
      }
    }
    return art
  }

  function cambiarFecha(event) {
    setCargarCuotasMora(true)
    setFechaSeleccionada(event.target.value)
  }

  if (datosCliente.length > 0 && creditoSeleccionado.length > 0) {
    return (
      <div className='container'>
        <div style={{ marginTop: '0.2em' }} align="center" className="row justify-content-center">
          <div className="col-lg-4 col-6" >
            <a onClick={params.goFinanciera} className='btn btn-success btn-sm'>Mi cuenta financiera</a>
          </div>
          <div className="col-lg-4 col-6" >
            <a onClick={params.goCasabonita} style={{ backgroundColor: '#f0e094', color: 'black' }} className='btn btn-primary btn-sm'>Mi cuenta casa bonita</a>
          </div>
          <div style={{ marginTop: '0.3em', textAlign: 'center' }} className='row justify-content-center'>
            <div className='col-lg-6 col-md-8 col-sm-12 col-12'>
              <label>Creditos: </label>
              <br />
              <select onChange={cambiarFecha} style={{ marginBottom: '0.4em' }} className="form-select">
                {creditos.map((item, index) => {
                  const articulo = setArticulosByFecha(item.fecha_prest)
                  return (
                    <option key={index} value={item.fecha_prest}>{item.fecha_prest + ": " + articulo}</option>
                  )
                })
                }
              </select>
              <TablaPrestamo titulo="Crédito" datosCliente={datosCliente} datosPrest={creditoSeleccionado} datosAbonos={abonosSeleccionado} cuotasMora={cuotasMora} fuente='creditoCasabonita'/>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <a style={{ display: 'none' }} id='btnGoLogin' onClick={params.goLogin} ></a>
        <Cargando />
      </div>
    )
  }
}

export default DCCasabonita