import React from 'react'
import PromoServices from '../services/PromoServices'
import { useState, useEffect } from 'react';
import GlobalFunctions from '../services/GlobalFunctions'
import Carousel from 'react-bootstrap/Carousel'
import GeneralMenu from './GeneralUI/GeneralMenu';

const Home = (params) => {
  const glob = new GlobalFunctions()
  const promoServices = new PromoServices()
  const [promos, setPromos] = useState([])
  const [cargarPromos, setCargarPromos] = useState(true)

  useEffect(() => {
    promoServices.getPromos().then((data) => {
      if (cargarPromos) {
        setCargarPromos(false)
        setPromos(data)
      }
    })
  })

  function loadingImgCarousel() {
    document.getElementById('spanCargandoCarousel').style.display = 'none'
  }

  function goProduct(id) {
    let href = "https://tucasabonita.site/product/" + id;
    window.open(href, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
  }

  return (
    <div style={{ marginTop: 3, textAlign: 'center' }} className='container'>
      <GeneralMenu goHome={params.goHome} validarGoMiCuenta={params.validarGoMiCuenta} goSolicitudes={params.goSolicitudes}></GeneralMenu>
      <h1 className='superTitulo' style={{ color: 'black', textAlign: 'center', fontSize: '1.5em' }}>Promociones tu casa bonita</h1>
      <span id='spanCargandoCarousel' className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <Carousel variant="dark" indicators={false}>
        {
          promos.map((item, index) => {
            return (
              <Carousel.Item className='textAlignCenter' key={index}>
                <img onClick={() => goProduct(item.referencia)} onLoad={loadingImgCarousel} className={'cursorPointer ' + params.thisWidth}
                  src={process.env.REACT_APP_URL_CASA + item.imagen}
                  alt=""
                />
                <h3 className='textAlignCenter fontSizeNormal'>{item.nombre}</h3>
                <button type='button' onClick={() => goProduct(item.referencia)} style={{ backgroundColor: 'green', marginTop: '0.5em' }} className="btn btn-success btn-md">Comprar</button>
              </Carousel.Item>
            )
          })
        }
      </Carousel>
      <br />
      <div style={{ marginTop: '1em' }} className='col-12 rounded'>
        <div className="card-body">
          <p style={{ marginTop: '0.5em', fontSize: '0.8em', color: 'black', textAlign: 'justify' }}>
            Lamentamos informarte que no daremos mas soporte a la aplicación financiera casa bonita disponible en playstore o google play.
            Agradecemos utilices la aplicación web para consultar los detalles de tu cuenta.
            Siempre podrás ingresar a la aplicación web a través de este enlace: <a href='https://financiera.tucasabonita.site/'>https://financiera.tucasabonita.site/</a>
          </p>
          <p style={{ marginTop: '0.5em', fontSize: '0.8em', color: 'black', textAlign: 'justify' }}>También puedes instalar la aplicación en tu celular o pc como se muestra en el siguiente video:</p>
          <iframe style={{ height: '20rem', width: '20rem' }} src="https://www.youtube.com/embed/HX2GdmgjN5g?si=aG2CycjgPkw36bUc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
      </div>




    </div>
  )
}

export default Home