import React from 'react'
import { useState, useEffect } from 'react';

const QuestionsTest = (params) => {
    const [questions, setQuestion] = useState({
        number: '',
        pregunta: '',
        respuestas: []
    })

    useEffect(() => {
        if (questions.number != params.questions.number) {
            setQuestion((valores) => ({
                ...valores,
                number: params.questions.number,
                pregunta: params.questions.pregunta,
                respuestas: params.questions.respuestas
            }))
        }
    })

    return (
        <div >
            <p style={{ textAlign: 'justify' }}>{questions.pregunta}</p>
            {questions.respuestas.map((item, index) => {
                return (
                    <button className='btn btn-outline-info' style={{ margin: '0.5em' }} onClick={()=>params.validarRespuesta(item)} key={index}>
                        {item.resp}
                    </button>
                )
            })}

        </div>
    )
}

export default QuestionsTest