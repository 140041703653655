import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import './css/general.css'
import Home from "./js/Home";
import { useState, useEffect } from 'react';
import Contact from "./js/Contact";
import Login from "./js/GeneralUI/Login";
import DCFinanciera from "./js/DCFinanciera/DCFinanciera";
import GlobalFunctions from './services/GlobalFunctions'
import DCCasabonita from "./js/DCCasabonita/DCCasabonita"
import PromoServices from '../src/services/PromoServices'
import Requests from "./js/Requests";
import RedirectToRequest from "./js/GeneralUI/RedirectToRequest"
import Swal from 'sweetalert2'

function App() {
  const glob = new GlobalFunctions()
  const promoServices = new PromoServices()
  const [btnTogglerActive, setBtnTogglerActive] = useState(false)
  const [thisWidth, setthisWidth] = useState('')
  const [datosUsuario, setDatosUsuario] = useState({
    usuario: glob.getCookie('usuario'),
    cedula: glob.getCookie('cedula')
  })
  const [mostrarMenuPerfil, setMostrarMenuPerfil] = useState('none')
  const [version, setVersion] = useState('')
  const [cambioPass, setCambioPass] = useState({
    actualPass: '',
    newPass: '',
    confirmNewPass: ''
  })

  useEffect(() => {
    setthisWidth(setDimensionPantalla())
    promoServices.getDatosGestionCartera().then((data) => {
      setVersion(data.otros1)
      // console.log(data.otros1+"//"+glob.getCookie('versionApp'))
      if (data.otros1 !== glob.getCookie('versionApp')) {
        glob.setCookie('versionApp', data.otros1, glob.setExpires('1'))
        borrarCache()
      }
    })
  }, [])

  useEffect(() => {
    check_session()
  }, [datosUsuario])

  function check_session() {
    if (glob.getCookie('cedula') !== '') {
      setMostrarMenuPerfil('inline')
    } else {
      setMostrarMenuPerfil('none')
    }
  }

  // function para forzar cache y actualizar app
  function borrarCache() {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        })
      });
      window.location.reload(true);
    }
  }

  function hideBar() {
    if (btnTogglerActive) {
      document.getElementById('btnToggler').click()
    }
  }

  function setDimensionPantalla() {
    let widthDiv
    if (window.screen.width < 600) {
      widthDiv = 'imgCarouselSm'
    } else {
      widthDiv = 'imgCarouselBig'
    }
    return widthDiv
  }

  function activarBtn() {
    if (btnTogglerActive) {
      setBtnTogglerActive(false)
    } else {
      setBtnTogglerActive(true)
    }
  }

  function validarFuncionBotonLogin() {
    if (datosUsuario.cedula !== '') {
      salir()
    } else {
      document.getElementById('linkLogin').click()
    }
  }

  function goFinanciera() {
    setDatosUsuario({
      usuario: glob.getCookie('usuario'),
      cedula: glob.getCookie('cedula')
    })
    document.getElementById('financiera').click()
  }

  function goCasabonita() {
    document.getElementById('casabonita').click()
  }

  function salir() {
    glob.setCookie('usuario', '', glob.setExpires('-1'))
    glob.setCookie('cedula', '', glob.setExpires('-1'))
    setMostrarMenuPerfil('none')
    setDatosUsuario({
      usuario: '',
      cedula: ''
    })
    document.getElementById('linkHome').click()
    document.getElementById('btnToggler').click()
  }

  function validarFuncionMenuNombre() {
    if (datosUsuario.cedula !== '') {
      document.getElementById('financiera').click()
    } else {
      document.getElementById('linkLogin').click()
    }
  }

  function goHome() {
    document.getElementById('linkHome').click()
  }

  function goContact() {
    document.getElementById('linkContact').click()
  }

  function goLogin() {
    document.getElementById('linkLogin').click()
  }

  function validarGoMiCuenta() {
    if (datosUsuario.cedula !== '') {
      document.getElementById('financiera').click()
    } else {
      document.getElementById('linkLogin').click()
    }
  }

  function goSolicitudes() {
    document.getElementById('linkRequest').click()
  }

  function cambioActualPass(e) {
    setCambioPass((valores) => ({
      ...valores,
      actualPass: e.target.value,
    }))
  }

  function sweetAlert(mensaje) {
    Swal.fire({
      title: mensaje,
      icon: 'warning',
      showCancelButton: false,
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true
    })
  }

  function validarPass() {
    if (cambioPass.actualPass == "") {
      sweetAlert("Ingresa tu actual contraseña!")
    } else {
      if (cambioPass.newPass == "") {
        sweetAlert("Ingresa una nueva contraseña!")
      } else {
        if (cambioPass.newPass != cambioPass.confirmNewPass) {
          sweetAlert("Las contraseñas no coinciden")
        } else {
          consultarServidor()
        }
      }
    }
  }

  function loadingOffChangePass() {
    document.getElementById("btnConfirmChangePass").style.display = ''
    document.getElementById("btnLoadingChangePass").style.display = 'none'
  }

  function loadingOnChangePass() {
    document.getElementById("btnConfirmChangePass").style.display = 'none'
    document.getElementById("btnLoadingChangePass").style.display = ''
  }

  function consultarServidor() {
    loadingOnChangePass()
    let url = process.env.REACT_APP_URL_SERV_PROMO + "get_clientes.php?modo=logueo&usu=" + datosUsuario.cedula + "&code=" + cambioPass.actualPass
    fetch(url)
      .then((response) => {
        return response.json()
      }).then((json) => {
        if (json[0].clave == cambioPass.actualPass) {
          const enlace = process.env.REACT_APP_URL_SERVS_LARCON + 'api/setemail/' + json[0].cedula + '/' + json[0].correo + '/' + cambioPass.newPass
          fetch(enlace)
            .then((response) => {
              return response.json()
            }).then((json) => {
              loadingOffChangePass()
              if (json > 0) {
                document.getElementById("btnCancelModalChangePass").click()
                hideBar()
                sweetAlert('Contraseña actualizada!')
              } else {
                sweetAlert('Ha ocurrido un error, comunicate con uno de nuestros asesores...')
              }
            })
        } else {
          loadingOffChangePass()
          sweetAlert("Actual contraseña incorrecta!")
        }
      })
  }

  function cambioNewPass(e) {
    setCambioPass((valores) => ({
      ...valores,
      newPass: e.target.value,
    }))
    if (cambioPass.confirmNewPass != e.target.value) {
      document.getElementById("labelConfirmPass").innerHTML = "Las nuevas contraseñas no coinciden..."
    } else {
      document.getElementById("labelConfirmPass").innerHTML = ""
    }
  }

  function cambioConfirmNewPass(e) {
    setCambioPass((valores) => ({
      ...valores,
      confirmNewPass: e.target.value,
    }))
    if (cambioPass.newPass != e.target.value) {
      document.getElementById("labelConfirmPass").innerHTML = "Las nuevas contraseñas no coinciden..."
    } else {
      document.getElementById("labelConfirmPass").innerHTML = ""
    }
  }

  return (
    <Router>
      <nav className="navbar navbar-expand-md justify-content-between fondoRojo">
        <div className="container">
          <button onClick={activarBtn} id='btnToggler' className="navbar-toggler" type="button" data-toggle="collapse" data-target="#opciones">
            <span className="navbar-toggler-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
            </span>
          </button>
          <Link to="/" id='linkHome' className="navbar-brand cursorPointer fuenteNavBar"  > <img className="rounded" src={require('./Images/logo192.png')} width="30" height="30" />
            <span style={{ marginLeft: '0.2em' }} className="colorBlanco">Financiera casa bonita</span>
          </Link>
          <div style={{ marginLeft: window.screen.width < 1300 ? '0em' : '5em' }} className="collapse navbar-collapse" id="opciones">
            <ul className="navbar-nav centrarAuto">
              <div className="dropdown-divider"></div>
              <div>
                <Link onClick={hideBar} className="fuenteNavBar" to="/">Inicio</Link>
              </div>
              <div className="dropdown-divider"></div>
              <div className="dropdown">
                <span className="fuenteNavBar" onClick={validarFuncionMenuNombre} style={{ marginLeft: '0.8em', textDecoration: 'underline' }}>{glob.getCookie('cedula') !== '' ? 'Hola ' + glob.getCookie('usuario') : 'Ingresar a mi cuenta'}</span>
                <a style={{ display: mostrarMenuPerfil, marginLeft: '0.1em' }} className="fuenteNavBar" id="dropdown2" data-toggle="dropdown">
                  <svg style={{ marginLeft: '0.1em' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-square" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                  </svg>
                </a>
                <div>
                </div>
                <div id="div_dropdown" className="dropdown-menu cursorPointer">
                  <Link className="dropdown-item" id='financiera' to="/financiera">Mi cuenta financiera</Link>
                  <Link id='casabonita' className="dropdown-item" to="/casabonita">Mi cuenta casa bonita</Link>
                  <Link id="btnChangePass" data-toggle="modal" data-target="#changePassModal" className="dropdown-item" >Cambiar contraseña</Link>

                  <a className="dropdown-item" onClick={salir} >
                    <span style={{ marginRight: '0.6em' }}>Salir</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-left" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z" />
                      <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="dropdown-divider"></div>
              <li className="nav-item">
                <div>
                  <Link id="linkRequest" onClick={hideBar} className="fuenteNavBar" to="/request/Im/already/customer/city/bga/stder">Solicitudes de crédito</Link>
                </div>
              </li>
              <div className="dropdown-divider"></div>
              <li className="nav-item">
                <Link id="linkContact" className="nav-link fuenteNavBar" onClick={hideBar} to="/contact">Contáctenos</Link>
              </li>
            </ul>
            <Link id="linkLogin" to="/login"></Link>
          </div>
        </div>
      </nav>
      <Routes>
        <Route path="/" element={<Home thisWidth={thisWidth} goHome={goHome} goLogin={validarFuncionBotonLogin} validarGoMiCuenta={validarGoMiCuenta} goSolicitudes={goSolicitudes} />} />
        <Route path="/contact" element={<Contact version={version} />} />
        <Route path="/login" element={<Login goFinanciera={goFinanciera} />} />
        <Route path="/financiera" element={<DCFinanciera goLogin={goLogin} goFinanciera={goFinanciera} goCasabonita={goCasabonita} />} />
        <Route path="/casabonita" element={<DCCasabonita goLogin={goLogin} goFinanciera={goFinanciera} goCasabonita={goCasabonita} />} />
        <Route path="/request/:id/:user/:phrase/:ref/:tt/:secreto" element={<Requests goHome={goHome} goContact={goContact} goLogin={validarFuncionBotonLogin} validarGoMiCuenta={validarGoMiCuenta} goSolicitudes={goSolicitudes} />} />
        <Route path="/request/:id/:user/:phrase/:ref/:tt" element={<RedirectToRequest />} />
      </Routes>
      {/*Dialogo cambiar contraseña*/}
      <div className="modal fade" id="changePassModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 style={{ textAlign: 'center', color: 'black' }} className="modal-title" id="exampleModalLabel">Cambia tu contraseña</h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <input style={{ marginBottom: '1.5em' }} type="password" placeholder="Escribe tu actual contraseña..." onChange={cambioActualPass} className="form-control" id="actualPass" value={cambioPass.actualPass} />
                <input type="password" placeholder="Escribe tu nueva contraseña..." onChange={cambioNewPass} className="form-control" id="newPass" value={cambioPass.newPass} />
                <label style={{ color: 'red' }} id="labelConfirmPass" htmlFor="repeatNewPass" className="col-form-label"></label>
                <input type="password" placeholder="Repite tu nueva contraseña..." onChange={cambioConfirmNewPass} className="form-control" id="repeatNewPass" value={cambioPass.confirmNewPass} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" id="btnCancelModalChangePass" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="button" id="btnConfirmChangePass" onClick={validarPass} className="btn btn-success">Cambiar contraseña.</button>
              <button id="btnLoadingChangePass" style={{ display: 'none' }} className="btn btn-secondary" type="button" disabled>
                <span style={{ marginRight: '0.5em' }} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*Fin dialogo cambiar contraseña*/}
    </Router>
  )
}

export default App;
