import React from 'react'
import { useState, useEffect } from 'react'
import GlobalFunctions from '../../services/GlobalFunctions'
import PasarelaService from '../../services/PasarelaService'
import WavaButton from '../GeneralUI/BotonWava';

const BotonPagos = (params) => {
    const [valorPagar, setValorPagar] = useState(0)
    const [valorPagarOtro, setValorPagarOtro] = useState(0)
    const [radioCuotasSelected, setRadioCuotasSelected] = useState(true)
    const glob = new GlobalFunctions()
    const pasarelaService = new PasarelaService()
    const [cargarWava, setCargarWava] = useState(false)
    const [datosWava, setCargarDatosWava] = useState({
        fuente: params.fuente == 'pagofinanciera' ? 'Pago credito tu casabonita' : 'Pago tu casabonita',
        url_redirect: params.url_redirect,
        datosCliente: params.datosCliente,
        ref: ''
    })

    useEffect(() => {
        document.getElementById('radio_valor_cuota').checked = true
    }, [])

    useEffect(() => {
        if (params.valor_cuotas !== valorPagar) {
            validarSiSaldoMayorQCuota()
        }
    })

    function validarSiSaldoMayorQCuota() {
        const saldo = parseInt(params.saldoTotal)
        const cuota = parseInt(params.valor_cuotas)
        if (saldo < cuota) {
            setValorPagar(params.saldoTotal)
        } else {
            setValorPagar(params.valor_cuotas)
        }
    }

    function calcular_pagar_con_cuota() {
        setRadioCuotasSelected(true)
        validarSiSaldoMayorQCuota()
    }

    function calcular_pagar_otro_valor() {
        setRadioCuotasSelected(false)
    }

    function establecerValorPagar() {
        let valor = 0
        if (radioCuotasSelected) {
            valor = valorPagar
        } else {
            if (valorPagarOtro > 2000) {
                valor = valorPagarOtro
            } else {
                alert("El valor a pagar debe ser mayor a 2000")
                window.location.reload()
                return
            }
        }
        return valor
    }

    function loadingOn() {
        document.getElementById('btnPagarLoading').style.display = 'inline'
        document.getElementById('botonPagar').style.display = 'none'
    }

    function loadingOff() {
        document.getElementById('btnPagarLoading').style.display = 'none'
        document.getElementById('botonPagar').style.display = 'inline'
    }

    function iniciarPagoWompi() {
        let valorPagar = establecerValorPagar()
        if (isNaN(valorPagar)) {
            alert('El valor a pagar debe ser mayor a 0!')
        } else {
            valorPagar = Math.trunc(valorPagar)
            const obj = {
                'valor': valorPagar+ "00",
                'producto': params.fuente + ". Wompi",
                'fecha_credito': params.fecha_prest
            }
            document.getElementById('amount').value = valorPagar+ "00"
            loadingOn()
            pasarelaService.getRefPago(obj).then((data) => {
                loadingOff()
                document.getElementById('ref').value = data[0].id
                document.getElementById('integrity').value = data[0].comentarios
                document.getElementById('form_wompi').submit()
            })
        }
    }

    function cargarBotonWava() {
        // sin doble cero de centavos es wava.
        let valorPagar = establecerValorPagar()
        if (isNaN(valorPagar)) {
            alert('El valor a pagar debe ser mayor a 0!')
        } else {
            valorPagar = Math.trunc(valorPagar)
            const obj = {
                'valor': valorPagar,
                'producto': params.fuente + ". Wava",
                'fecha_credito': params.fecha_prest
            }
            pasarelaService.getRefPago(obj).then((data) => {
                setCargarDatosWava((valores) => ({
                    ...valores,
                    ref: data[0].id,
                    valorPagar: valorPagar
                }))
                setTimeout(() => {
                    if (cargarWava != true) {
                        setCargarWava(true)
                    } else {
                        window.location.reload()
                    }
                }, 100);
            })
        }
    }

    function cambioValorPagar(e) {
        const valor = parseInt(e.target.value)
        const saldo = parseInt(params.saldoTotal)
        if (saldo < valor) {
            setValorPagarOtro(saldo)
        } else {
            setValorPagarOtro(e.target.value)
        }
    }

    function abrirDialogoValor(botonPago) {
        if (botonPago == 'wava') {
            document.getElementById('botonPagar').style.display = 'none'
            document.getElementById('btnDialogoWava').style.display = ''
        } else {
            document.getElementById('btnDialogoWava').style.display = 'none'
            document.getElementById('botonPagar').style.display = ''
        }
        document.getElementById('btnDialogoWompi').click()
    }
//window.screen.width > 600 ? 'row justify-content-center' : 'row justify-content-between'
    return (
        <div >
            <div style={{ marginBottom: '0.5em', width: '98%' }} className='d-flex justify-content-center'>
                <div className='col-4' style={{ cursor: 'pointer' }} >
                    <div onClick={() => abrirDialogoValor('wompi')} className='border border-primary rounded' style={{ width: '7rem', height: '6.5em', textAlign: 'center' }} >
                        <p className='wavaButton' >Paga con wompi</p>
                        <img style={{ width: '65%', height: 'auto', marginTop: '-0.5em' }} src={process.env.REACT_APP_URL_CASA + 'Imagenes_config/wompi_btn.png'} alt="" />
                    </div>
                </div>
                <div className='col-4' style={{ cursor: 'pointer', display: 'none' }} >
                    <div onClick={() => abrirDialogoValor('wava')} className='border border-success rounded' style={{ width: '7rem', height: '6.5em', textAlign: 'center' }} >
                        <p style={{ margin: '0.1em' }} className='wavaButton'>¡Paga con nequi!</p>
                        <img className='align-middle' style={{ width: '40%', height: 'auto' }} src={process.env.REACT_APP_URL_CASA + 'Imagenes_config/neki.png'} alt="" />
                    </div>
                </div>
            </div>

            <button id='btnDialogoWompi' data-toggle="modal" data-target="#dialogo1" style={{ display: 'none' }}></button>
            <div className="modal fade" id="dialogo1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div onClick={calcular_pagar_con_cuota} style={{ cursor: 'pointer', textAlign: 'center', padding: '0.4em' }} className="col-12 col-lg-6 col-md-12 col-sm-12 border border-primary rounded">
                                            <form id="form_wompi" action="https://checkout.wompi.co/p/" method="GET">
                                                <input type="hidden" name="public-key" value="pub_prod_mm5Qq0EJtZhzNzjV4Vm6fLQx6aHhCbjS" />
                                                <input type="hidden" name="currency" value="COP" />
                                                <input type="hidden" id="amount" name="amount-in-cents" value="0" />
                                                <input type="hidden" id="ref" name="reference" value="RE1" />
                                                <input type="hidden" id='integrity' name="signature:integrity" value="" />
                                                <input type="hidden" id='redirect' name="redirect-url" value={params.url_redirect} />
                                                {/*Pongo estos porque wompi me los pide y necesito line 1 para saber que es pago financiera, credito casa bonita.*/}
                                                <input type="hidden" id='direccion' name="shipping-address:address-line-1" value={params.fuente} />
                                                <input type="hidden" name="shipping-address:country" value="CO" />
                                                <input type="hidden" id='cliente' name="shipping-address:phone-number" value={glob.getCookie('cedula')} />
                                                <input type="hidden" id='nCredito' name="shipping-address:city" value={params.nCredito} />
                                                <input type="hidden" name="shipping-address:region" value='Santander' />

                                                <input onChange={calcular_pagar_con_cuota} type="radio" checked={radioCuotasSelected ? true : false} id="radio_valor_cuota" name="valor_pago" value="valor_cuota" />
                                                <strong style={{ marginLeft: '0.2em' }}>Pagar valor de la cuota:</strong>
                                                <br />
                                                <input className='rounded' style={{ width: '6em' }} type="text" readOnly id="input_valor_cuota" name="input_valor_cuota" value={glob.formatNumber(valorPagar)} />
                                                <br />
                                            </form>
                                        </div>
                                        <div onClick={calcular_pagar_otro_valor} style={{ cursor: 'pointer', textAlign: 'center', padding: '0.4em' }} className="col-12 col-lg-6 col-md-12 col-sm-12 border border-success rounded">
                                            <input type="radio" onChange={calcular_pagar_otro_valor} checked={radioCuotasSelected ? false : true} id="radio_otro_valor" name="valor_pago" value="Otro valor" />
                                            <strong style={{ marginLeft: '0.2em' }}>Pagar otro valor</strong>
                                            <br />
                                            <input className='rounded' onChange={cambioValorPagar} value={valorPagarOtro} placeholder='Ingresa el valor a pagar' style={{ width: '6em' }} type="number" name="input_otro_valor" />
                                            <br />
                                        </div>
                                        <div style={{ marginTop: '1em' }} className="row justify-content-around">
                                            <div className="col-6">
                                                <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal">Cancel</button>
                                            </div>
                                            <div className="col-6">
                                                <button id='btnDialogoWava' style={{ display: 'none', whiteSpace: 'nowrap' }} onClick={cargarBotonWava} className="btn btn-success" data-toggle="modal" data-target="#dialogoWava" >Ir a Pagar</button>
                                                <button className="btn btn-success btn-sm" style={{ display: 'none', whiteSpace: 'nowrap' }} id='botonPagar' onClick={iniciarPagoWompi} type="button">Ir a Pagar</button>
                                                <button id='btnPagarLoading' style={{ display: 'none', backgroundColor: 'gray' }} className="btn btn-success btn-sm" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    ...
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Dialogo botn wava*/}
                <div className="modal fade" id="dialogoWava">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="modal-body">
                                    <div className="container-fluid">
                                        <div style={{ textAlign: 'center' }}>
                                            <h5 style={{ fontWeight: 'bold' }}>Resumen de pago:</h5>
                                            <li style={{ marginTop: '0.5em' }}>Cliente: {datosWava.datosCliente[0].nombre}</li>
                                            <li>Valor a pagar : ${glob.formatNumber(datosWava.valorPagar)}</li>
                                            <li>Pago con Nequi</li>
                                            <li>Télefono {datosWava.datosCliente[0].telefonos[0]}</li>
                                            <li>Producto: {params.fuente == 'pagofinanciera' ? 'Pago financiera tu casabonita' : 'Pago credito productos tu casabonita'}</li>
                                        </div>
                                        <br />
                                        <div className="row justify-content-around">
                                            <div className="col-3">
                                                <button type="button" style={{ height: '2.8em', marginTop: '0.1em' }} className="btn btn-danger" data-dismiss="modal">Cancelar</button>
                                            </div>
                                            <div className="col-3">
                                                <div id='btnWava'>
                                                    {cargarWava ?
                                                        <WavaButton datosWava={datosWava}></WavaButton>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                                <button style={{ display: 'none', backgroundColor: 'gray' }} className="btn btn-success" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    ...
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Fin dialogo btn wava*/}
            </div>
        </div>
    )
}

export default BotonPagos