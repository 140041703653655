import React from 'react'
import imgLoading from '../../Images/loading.gif'

const Cargando = () => {
  return (
    <div style={{textAlign: 'center'}} className='container'>
      <br></br><br></br><br></br>
      <span>Cargando info...</span>
      <br></br>
      <img src={imgLoading}  width="100" height="100"></img>
  </div>
  )
}

export default Cargando