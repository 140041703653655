import React, { useEffect } from 'react';
import GlobalFunctions from '../../services/GlobalFunctions'

function WavaButton(params) {
  const glob = new GlobalFunctions()

  const scriptParams = [
    { param: 'class', value: 'wava-button' },
    { param: 'data_wava_merchant_key', value: '451' },
    { param: 'data_wava_amount', value: params.datosWava.valorPagar },
    { param: 'data_wava_product_name', value: params.datosWava.fuente },
    { param: 'data_wava_order_key', value: params.datosWava.ref },
    { param: 'data_wava_redirect_link', value: params.datosWava.url_redirect },
    { param: 'data_wava_shopper_email', value: "sinemail@gmail.com" },
    { param: 'data_wava_shopper_phone', value: params.datosWava.datosCliente[0].telefonos[0].trim()},
    { param: 'data_wava_shopper_first_name', value: params.datosWava.datosCliente[0].nombre},
    { param: 'data_wava_shopper_last_name', value: params.datosWava.datosCliente[0].apellidos },
    { param: 'data_wava_shopper_document_type', value: 'CC' },
    { param: 'data_wava_shopper_document_number', value: glob.getCookie('cedula') },
    { param: 'data_wava_button_text', value: 'Pagar' },
    { param: 'data_wava_button_class', value: '' },
  ];

  useEffect(() => {
    const wavaForm = document.getElementById('wavaForm');
    if (wavaForm.childNodes.length == 0) {
      const script = document.createElement('script');
      script.src = 'https://wava-assets.s3.amazonaws.com/Button/payment.js';
      script.async = true;
      for (let i = 0; scriptParams.length > i; i++) {
        script.setAttribute(scriptParams[i].param, scriptParams[i].value);
      }
      wavaForm.appendChild(script);
    }
  }, []);

  return (
    <form id="wavaForm" />
  );
}
export default WavaButton;
