import React from 'react'
import FinancieraService from '../../services/FinancieraService'
import { useState, useEffect } from 'react';
import Cargando from '../GeneralUI/Cargando'
import GlobalFunctions from '../../services/GlobalFunctions'
import TablaPrestamo from '../GeneralUI/TablaPrestamo'

const DCFinanciera = (params) => {
  const service = new FinancieraService()
  const glob = new GlobalFunctions()
  const [datosCliente, setDatosCliente] = useState([])
  const [datosPrest, setDatosPrest] = useState([])
  const [cuotasMora, setCuotaMora] = useState(0)
  const [datosAbonos, setDatosAbonos] = useState([])
  // variables para validar si cargar datos y evitar loops infinitos
  const [cargarDatosCliente, setCargarDatosCliente] = useState(true)
  const [cargarCuotasMora, setCargarCuotasMora] = useState(true)

  useEffect(() => {
    if (glob.validarSesion()) {
      if (document.getElementById('btnGoLogin') !== null) {
        document.getElementById('btnGoLogin').click()
      }
    }
    if (cargarDatosCliente) {
      service.getDatosCliente().then((data) => {
        setCargarDatosCliente(false)
        setDatosCliente(data)
      })
      service.getDatosPrest().then((data) => {
        setDatosPrest(data)
      })
      service.getAbonos().then((data) => {
        setDatosAbonos(data)
      })
    }
  })

  useEffect(() => {
    if (cargarCuotasMora && datosPrest.length > 0) {
      if (datosPrest[0].valor_cuotas > 0) {
        setCargarCuotasMora(false)
        service.getCuotasMora(datosPrest[0].periodicidad, datosPrest[0].fecha_prest, datosPrest[0].tt_abonos, datosPrest[0].valor_cuotas, datosPrest[0].n_cuotas).then((data) => {
          setCuotaMora(data)
        })
      }
    }
  }, [datosPrest])

  if (datosCliente.length > 0 && datosPrest.length > 0) {
    return (
      <div className='container'>
        <div style={{ marginTop: '0.2em' }} align="center" className="row justify-content-center">
          <div className="col-lg-4 col-6" >
            <a onClick={params.goFinanciera} className='btn btn-success btn-sm'>Mi cuenta financiera</a>
          </div>
          <div className="col-lg-4 col-6" >
            <a onClick={params.goCasabonita} style={{ backgroundColor: '#f0e094', color: 'black' }} className='btn btn-primary btn-sm'>Mi cuenta casa bonita</a>
          </div>
          <div style={{ marginTop: '0.3em', textAlign: 'center' }} className='row justify-content-center'>
            <div className='col-lg-6 col-md-8 col-sm-12 col-12'>
              <TablaPrestamo titulo="préstamo" datosCliente={datosCliente} datosPrest={datosPrest} datosAbonos={datosAbonos} cuotasMora={cuotasMora} fuente='pagofinanciera'/>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <a style={{ display: 'none' }} id='btnGoLogin' onClick={params.goLogin} ></a>
        <Cargando />
      </div>
    )
  }

}

export default DCFinanciera