import GlobalFunctions from '../services/GlobalFunctions'

class PasarelaService{
  glob= new GlobalFunctions()

  getRefPago = async (obj) => {
        let content = [];
        const url = process.env.REACT_APP_URL_SERV_PROMO+'get_compras.php?modo=refWompi&cliente='+this.glob.getCookie('cedula')+'&totalVenta='+obj.valor+'&fecha='+this.glob.getFecha()+'&estado=Iniciada. '+obj.producto+'&fecha_prest='+obj.fecha_credito
        const respuesta = await fetch(url);
        const data = await respuesta.json();
        data.forEach(element => {
            content.push(element)
        });
        return content;
  }

}

export default PasarelaService