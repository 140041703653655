import GlobalFunctions from '../services/GlobalFunctions'

class CasabonitaService{
    glob= new GlobalFunctions()

    getDatosCliente = async () => {
      let content = []
      const url = process.env.REACT_APP_URL_SERV_PROMO+"get_clientes.php?modo=datosCliente&usuario="+this.glob.getCookie('cedula')+"&clave="+this.glob.getCookie('clave')
      const respuesta = await fetch(url)
      const data = await respuesta.json()
      data.forEach(element => {
        content.push(element)
      });
      return content;
    }

    getDatosCredito = async () => {
      let content = []
      const url = process.env.REACT_APP_URL_SERVS+"/getPrestamos.php?modo=getCreditoCasaByCed&cedula="+this.glob.getCookie('cedula')
      const respuesta = await fetch(url)
      const data = await respuesta.json()
      data.forEach(element => {
        content.push(element)   
      });
      return content;
    }

    getAbonosCredito = async () => {
      let content = []
      const url = process.env.REACT_APP_URL_SERVS+"/getAbonos.php?modo=getAbonosCasaByCed&cedula="+this.glob.getCookie('cedula')
      const respuesta = await fetch(url)
      const data = await respuesta.json()
      if(data.length>0){
        data.forEach(element => {
          content.push(element)
        });
      }
      return content;
    }

    getCompra = async () => {
      let content = []
      const url = process.env.REACT_APP_URL_SERVS+"/getComprasCreditosCasa.php?modo=getArtByFecha&cedula="+this.glob.getCookie('cedula')
      const respuesta = await fetch(url)
      const data = await respuesta.json()
      if(data.length>0){
        data.forEach(element => {
          content.push(element)
        });
      }
      return content;
    }

}

export default CasabonitaService