import React from 'react'
import { redirect } from 'react-router-dom'

const RedirectToRequest = () => {
    window.location= "https://financiera.tucasabonita.site/request/Im/already/customer/city/bga/sder"
  return (
    <div>   
    </div>
  )
}

export default RedirectToRequest