import React from 'react'
import '../../css/login.css'
import { useState, useEffect } from 'react';
import GlobalFunctions from '../../services/GlobalFunctions'
import imgAsesor from '../../Images/asesor.png'
import FinancieraService from '../../services/FinancieraService'
import Swal from 'sweetalert2'
import QuestionsTest from './QuestionsTest';


const Login = (params) => {
  const glob = new GlobalFunctions()
  const [contraseña, setContraseña] = useState('')
  const [loginUsuario, setLoginUsuario] = useState('')
  const [keepUsu, setKeepUsu] = useState(false)
  const [notificacion, setNotificacion] = useState(params.notificacion)
  const service = new FinancieraService()
  const [goodQuestions, setGoodQuestions] = useState(0)
  const [intentos, setIntentos] = useState(0)
  const [showQuestions, setShowQuestion] = useState('none')
  const [questions, setQuestion] = useState({
    number: '',
    pregunta: '',
    respuestas: [],
    data: {}
  })

  useEffect(() => {
    checkSetLogin()
  }, [])

  function checkSetLogin() {
    if (glob.getCookie('keepUsu') == 'true') {
      setKeepUsu(true)
      setLoginUsuario(glob.getCookie('loginUsuario'))
      setContraseña(glob.getCookie('contraseña'))
    }
  }

  function validarDatos() {
    if (loginUsuario != '' && contraseña != '') {
      consultarServidor()
      setNotificacion('')
      document.getElementById('imgLoading').style.display = ''
    } else {
      setNotificacion("No haz ingresado datos!")
    }
  }

  function consultarServidor() {
    let url = process.env.REACT_APP_URL_SERV_PROMO + "get_clientes.php?modo=logueo&usu=" + loginUsuario + "&code=" + contraseña
    fetch(url)
      .then((response) => {
        return response.json()
      }).then((json) => {
        validarContraseña(json)
      })
  }

  function validarContraseña(js) {
    if (js[0].usuario == 'No existe') {
      contraseñaIncorrecta()
    } else {
      if (js[0].clave == contraseña) {
        glob.setCookie('usuario', js[0].usuario, glob.setExpires('1'))
        glob.setCookie('cedula', js[0].cedula, glob.setExpires('1'))
        glob.setCookie('clave', js[0].clave, glob.setExpires('1'))
        document.getElementById('imgLoading').style.display = 'none'
        document.getElementById('inputGoFinanciera').click()
        checkKeepDatos()
      } else {
        contraseñaIncorrecta()
      }
    }
  }

  function contraseñaIncorrecta() {
    setNotificacion('Datos incorrectos!')
    document.getElementById('imgLoading').style.display = 'none'
  }

  function checkKeepDatos() {
    if (keepUsu) {
      glob.setCookie('keepUsu', 'true', glob.setExpires('1'))
      glob.setCookie('loginUsuario', loginUsuario, glob.setExpires('1'))
      // para llenar el formulario es cookie contraseña... para efectos del sistema otro nombre......
      glob.setCookie('contraseña', contraseña, glob.setExpires('1'))
    } else {
      glob.setCookie('keepUsu', false, glob.setExpires('-1'))
      glob.setCookie('loginUsuario', '', glob.setExpires('-1'))
      glob.setCookie('contraseña', '', glob.setExpires('-1'))
    }
  }

  function onChangeUsuario(e) {
    setLoginUsuario(e.target.value)
  }

  function onChangeContraseña(e) {
    setContraseña(e.target.value)
  }

  function mantenerDatos(e) {
    setKeepUsu(e.target.checked)
  }

  function goWhats() {
    let href = "https://api.whatsapp.com/send?phone=0573116186785&text=Hola! Necesito ayuda con mi contraseña!";
    window.open(href, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
  }

  function fetchDatos() {
    let ced = document.getElementById('inputCedula').value
    if (ced != '') {
      service.getDatosParaFormulario(ced).then((data) => {
        if (data.nombre == "") {
          sweetAlert("¡No se ha encontrado información! Comunicate con un asesor.")
        } else {
          if (data.correo == "") {
            setShowQuestion('')
            cargarPregunta1(data)
          } else {
            alertEnvioCorreo(data.correo, ced)
          }
        }
      })
    } else {
      sweetAlert("Ingresa tu número de cédula!")
    }
  }

  function cargarPregunta1(data) {
    const pregunta1 = "Selecciona la dirección que te resulte familiar:"
    const enc = data.direccion.split(" ")
    const resp1 = {
      resp: enc[0] + " " + enc[1],
      resul: true
    }
    const resp2 = {
      resp: enc[0] + " " + getNumber() + getNumber(),
      resul: false
    }
    const resp3 = {
      resp: enc[0] + " " + getNumber() + getNumber(),
      resul: false
    }
    const array = []
    array.push(resp1)
    array.push(resp2)
    array.push(resp3)
    mezclarArreglo(array);
    const arreglo = {
      number: 1,
      pregunta: pregunta1,
      respuestas: array,
      data: data
    }
    setQuestion(arreglo)
  }

  const mezclarArreglo = arreglo => {
    for (let i = arreglo.length - 1; i > 0; i--) {
      let indiceAleatorio = Math.floor(Math.random() * (i + 1));
      let temporal = arreglo[i];
      arreglo[i] = arreglo[indiceAleatorio];
      arreglo[indiceAleatorio] = temporal;
    }
  }

  function getNumber() {
    let num = Math.ceil(Math.random() * 9);
    return num
  }

  function alertEnvioCorreo(email, ced) {
    Swal.fire({
      title: "Se enviará tu contraseña al email: " + email,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      confirmButtonColor: "green",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchSaveEmail(email, ced)
        document.getElementById("btnEnvioContraToEmail").click()
      }
    });
  }

  function sweetAlert(mensaje) {
    Swal.fire({
      title: mensaje,
      icon: 'warning',
      showCancelButton: false,
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true
    })
  }

  function alertDone(icon) {
    Swal.fire({
      position: "top-center",
      icon: icon == "success" ? "success" : "error",
      title: icon == "success" ? "Correcto!" : "Incorrecto!",
      showConfirmButton: false,
      timer: 1500
    });
  }

  function validarRespuesta(item) {
    let buenasRespuestas = parseInt(goodQuestions)
    let intento = parseInt(intentos) + 1
    setIntentos(intento)
    if (item.resul) {
      alertDone("success")
      buenasRespuestas = buenasRespuestas + 1
    } else {
      buenasRespuestas = buenasRespuestas - 1
      alertDone("error")
    }
    setGoodQuestions(buenasRespuestas)
    if (questions.number == 1) {
      cargarPregunta2()
    } else {
      if (questions.number == 2) {
        cargarPregunta3()
      }
    }
    if (buenasRespuestas <= -3 || intento >= 3) {
      sweetAlert("Has agotado las oportunidades por hoy...")
      document.getElementById('btnClosepassToEmailModal').click()
      document.getElementById("btnEnvioContraToEmail").style.display = 'none'
      enviarCorreo('cezar_mh86@hotmail.com', 'Intento fallido de configuracion de email a cuenta con cedula: ' + questions.data.cedula + '-> ' + questions.data.nombre)
    }
    if (buenasRespuestas >= 3) {
      document.getElementById('btnClosepassToEmailModal').click()
      modalSaveEmail()
    }
  }

  function modalSaveEmail() {
    Swal.fire({
      title: "Bien! Ingresa tu email, será asociado a tu cuenta:",
      input: "email",
      reverseButtons: true,
      confirmButtonText: "Guardar correo",
      showCancelButton: true,
      confirmButtonColor: "green",
      preConfirm: (email) => {
        fetchSaveEmail(email, "")
      }
    })
  }

  function fetchSaveEmail(email, cedula) {
    document.getElementById('imgLoading').style.display = ''
    let ced = ""
    if (cedula == "") {
      ced = questions.data.cedula.replace("'", "")
      ced = ced.replace("'", "")
    } else {
      ced = cedula
    }
    let contra = getNumber().toString() + getNumber().toString() + getNumber().toString() + getNumber().toString()
    const enlace = process.env.REACT_APP_URL_SERVS_LARCON + 'api/setemail/' + ced + '/' + email + '/' + contra
    fetch(enlace)
      .then((response) => {
        return response.json()
      }).then((json) => {
        if (cedula == "") {
          enviarCorreo('cezar_mh86@hotmail.com', 'Email guardado de: ' + ced + ': ' + questions.data.nombre + ' -> ' + email)
        }
        enviarCorreo(email, 'Esta es tu nueva clave para ingresar a la aplicacion: ' + contra)
        document.getElementById('imgLoading').style.display = 'none'
        sweetAlert('¡Clave enviada a tu email!')
      })
  }

  function enviarCorreo(email, message) {
    const url = "https://financiera.tucasabonita.site"
    const enlace = url + '/mail.php?app=' + url + "&to=" + email + "&message=" + message + "&subject=Modificacion de credenciales de inicio de sesion"
    fetch(enlace)
      .then((response) => {
        return response.json()
      }).then((json) => {
        console.log(json)
      })
  }

  function cargarPregunta2() {
    const pregunta = "Selecciona el télefono que te resulte familiar:"
    const enc = questions.data.tel_fijo.split("//")
    const resp1 = {
      resp: enc[1],
      resul: true
    }
    const resp2 = {
      resp: enc[1].slice(0, 4) + getNumber() + getNumber() + getNumber() + getNumber() + getNumber() + getNumber() + getNumber(),
      resul: false
    }

    const resp3 = {
      resp: enc[1].slice(0, 4) + getNumber() + getNumber() + getNumber() + getNumber() + getNumber() + getNumber() + getNumber(),
      resul: false
    }
    const array = []
    array.push(resp1)
    array.push(resp2)
    array.push(resp3)
    mezclarArreglo(array);
    const arreglo = {
      number: 2,
      pregunta: pregunta,
      respuestas: array,
      data: questions.data
    }
    setQuestion(arreglo)
  }

  function cargarPregunta3() {
    const pregunta = "Selecciona la última periodicidad de pagos que elegiste:"
    let resp1 = {}
    let resp2 = {}
    let resp3 = {}
    if (questions.data.periodicidad == "") {
      resp1 = {
        resp: "mensual",
        resul: true
      }
      resp2 = {
        resp: "quincenal",
        resul: true
      }
      resp3 = {
        resp: "semanal",
        resul: true
      }
    } else {
      resp1 = {
        resp: questions.data.periodicidad,
        resul: true
      }
      resp2 = {
        resp: "quincenal",
        resul: false
      }
      resp3 = {
        resp: "semanal",
        resul: false
      }
    }
    const array = []
    array.push(resp1)
    array.push(resp2)
    array.push(resp3)
    mezclarArreglo(array);
    const arreglo = {
      number: 3,
      pregunta: pregunta,
      respuestas: array,
      data: questions.data
    }
    setQuestion(arreglo)
  }

  return (
    <div style={{}} className="container">
      <div className="row justify-content-center">
        <div style={{ borderStyle: 'double', borderColor: 'red', marginTop: '4%', textAlign: 'center' }} className="col-lg-6 col-10 login-box">
          <h2 style={{ color: 'black', textAlign: 'center' }} >Bienvenid@ a tu gestión de cuenta!</h2>
          <p style={{ color: 'black', textAlign: 'justify' }}>Ingresando con tus datos podrás:
            <li>Consultar el saldo de tu cuenta.</li>
            <li>Consultar abonos.</li>
            <li>Realizar pagos.</li>
          </p>
          <p style={{ textAlign: 'center', color: 'red' }} >{notificacion}</p>
          <img id='imgLoading' style={{ display: 'none' }} src={process.env.REACT_APP_URL_CASA + '/Imagenes_config/loading.gif'} width="80" height="80"></img>
          <form style={{ textAlign: 'center' }}>
            <input type="text" id="usuario" onChange={onChangeUsuario} name="user1" required size="50" placeholder="E-mail o cédula" value={loginUsuario} />
            <br /> <br />
            <input type="password" id="clave" onChange={onChangeContraseña} name="contra1" required size="50" placeholder="Clave" value={contraseña} />
            <br /> <br />
            <label style={{ color: 'black', cursor: 'pointer' }}>Mantener contraseña:
              <input id="checkbox" checked={keepUsu} onChange={mantenerDatos} type="checkbox" name="save" />
            </label>
            <input type='hidden' id='inputGoFinanciera' onClick={params.goFinanciera} />
            <br /> <br />
            <input onClick={validarDatos} style={{ backgroundColor: 'green' }} className="btn btn-success btn-md" type="button" value="Ingresar" />
            <br />
          </form>
          <div >
            <br />
            <input id="btnOlvideContraseña" data-toggle="modal" data-target="#olvideContraModal" className="btn btn-light btn-sm" type="button" value="Olvidé mi contraseña" />
          </div>
          {/*Dialogo olvide contraseña*/}
          <div className="modal fade" id="olvideContraModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 style={{ textAlign: 'center', color: 'black' }} className="modal-title" id="exampleModalLabel">Olvidé mi contraseña!</h5>
                </div>
                <div className="modal-body text-center">
                  <p style={{ textAlign: 'justify', color: 'black' }}>Elije una opción:</p>
                  <img className="img-fluid" alt="" style={{ margin: '2px', width: '40%', height: '40%' }} src={imgAsesor}></img>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                  <button onClick={goWhats} type="button" className="btn btn-success">Solicitar contraseña a un asesor.</button>
                  <button data-toggle="modal" data-target="#passToEmailModal" id='btnEnvioContraToEmail' data-dismiss="modal" type="button" className="btn btn-primary">Enviar contraseña a mi correo electronico.</button>
                </div>
              </div>
            </div>
          </div>
          {/*Fin dialogo olvide contraseña*/}
          {/*Dialogo contraseña al correo*/}
          <div id="passToEmailModal" className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title"></h5>
                  <a type="button" className='btn' data-dismiss="modal" aria-label="Close">
                    <i id='btnClosepassToEmailModal' style={{ color: 'red' }} className="fa-solid fa-rectangle-xmark fa-2xl rounded"></i>
                  </a>
                </div>
                <div className="modal-body">
                  <input id='inputCedula' className='form-control' type="number" placeholder="Ingresa tu número de cédula" />
                  <div id='divPreguntas' style={{ color: 'black', marginTop: '0.8em', display: showQuestions }}>
                    <p style={{ color: 'red', textAlign: 'left' }}>¡Aún no tienes un e-mail registrado!
                      Responde las siguientes preguntas para proceder a registrar tu e-mail: </p>
                    <span style={{ textAlign: 'left' }}>Pregunta N° {questions.number} de 3.</span>
                    <QuestionsTest validarRespuesta={validarRespuesta} questions={questions}></QuestionsTest>
                  </div>
                </div>
                <div className="modal-footer">
                  <button id='btnContinuar' type="button" onClick={fetchDatos} className="btn btn-primary">Continuar</button>
                </div>
              </div>
            </div>
          </div>
          {/*Fin Dialogo contraseña al correo*/}
        </div>
      </div>
    </div>
  )
}

export default Login