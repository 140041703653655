import React from 'react'

const DialogoRespuestaSolicitud = (params) => { 


  return (
    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 style={{textAlign: 'center'}} className="modal-title" id="exampleModalLabel">Solicitud enviada correctamente</h5>
            </div>
            <div className="modal-body text-center">
                <p style={{textAlign: 'justify'}}>{params.datosDialogo.mensaje}</p>
                <img className='rounded' style={{width:'160px', margin:'20px', height:'160px'}} src={params.datosDialogo.imagen}></img>
            </div>
            <div className="modal-footer">
                <button type="button" onClick={params.goContact} style={{display: params.datosDialogo.saldo > 0 ? 'inline': 'none'}} className="btn btn-primary">Contacto</button>
                <button onClick={params.goHome} type="button" className="btn btn-success">Terminar</button>
            </div>
            </div>
        </div>
    </div>
  )
}

export default DialogoRespuestaSolicitud