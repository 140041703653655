import React from 'react'
import CopyRight from '../js/GeneralUI/CopyRight'
import PromoServices from '../services/PromoServices'
import { useState, useEffect } from 'react'

const Contact = (params) => {
  const [datos, setDatos] = useState([])
  const [cargarDatos, setCargarDatos] = useState(true)
  const promoServices= new PromoServices()

  useEffect(() => {
    if(cargarDatos){
      promoServices.getDatos().then((data)=>{
        setCargarDatos(false)
        setDatos(data)    
      })
    }
  })  

function goWhats(tel){
  let href="https://api.whatsapp.com/send?phone=057"+tel+"&text=";
  window.open(href, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=800, height=600");
}

if(datos.length>0){
  return (  
    <footer style={{backgroundColor:'#f9f9c5'}} className="page-footer font-small indigo">
      <div className="container">
        <div className="row d-flex text-center justify-content-center mb-md-0 mb-4">
          <div className="col-md-8 col-12 mt-5">
            <div className="row">
                    <h5>Escríbenos: </h5>
                    {datos[0].listaImagenes.map((item, index)=>{
                      return(
                          <span style={{margin:'2px'}} key={index}>
                          <a onClick={()=>goWhats(item)} className='cursorPointer'><img style={{marginRight:'4px'}} src={process.env.REACT_APP_URL_CASA+'/Imagenes_config/whatsapp1.png'}/>
                            <button className='btn btn-primary'>{item}</button>
                          </a> 
                          <br/><br/>
                        </span> 
                      )
                    })}
            </div>
          </div>
        </div>
        <CopyRight version={params.version} />
      </div>
    </footer>
      )
}
}

export default Contact