import GlobalFunctions from '../services/GlobalFunctions'

class PromoServices{
  glob= new GlobalFunctions()

  getPromos = async () => {
    let content = [];
    const url = process.env.REACT_APP_URL_SERV_PROMO+"get_categorias.php?code=promo"
    const respuesta = await fetch(url);
    const data = await respuesta.json();
    data.forEach(element => {
      content.push(element)
    });
    return content;
  }

  // Obtiene los datos de casabonita web (se necesitan los telefonos). No de gestion cartera.
  getDatos = async () => {
    let content = [];
    const url = process.env.REACT_APP_URL_SERV_PROMO+"datos.php"
    const respuesta = await fetch(url);
    const data = await respuesta.json();
    data.forEach(element => {
      content.push(element)
    });
    return content;
  }

  // Obtiene los datos de gestion cartera. (se necesita la version app)
  getDatosGestionCartera = async () => {
    const url = process.env.REACT_APP_URL_SERVS+"/getDatos.php?modo=datosGenerales"
    const respuesta = await fetch(url);
    const data = await respuesta.json();
    return data;
  }
}

export default PromoServices