import GlobalFunctions from '../services/GlobalFunctions'

class FinancieraService{
  glob= new GlobalFunctions()

  getDatosCliente = async () => {
    let content = []
    const url = process.env.REACT_APP_URL_SERV_PROMO+"get_clientes.php?modo=datosCliente&usuario="+this.glob.getCookie('cedula')+"&clave="+this.glob.getCookie('clave')
    const respuesta = await fetch(url)
    const data = await respuesta.json()
    data.forEach(element => {
      content.push(element)
    });
    return content;
  }

  getDatosPrest = async () => {
    let content = []
    const url = process.env.REACT_APP_URL_SERVS+"/getPrestamos.php?modo=getPrestamoByCedFiltroSaldo&cedula="+this.glob.getCookie('cedula')
    const respuesta = await fetch(url)
    const data = await respuesta.json()
    data.forEach(element => {
      content.push(element)   
    });
    return content;
  }

  getCuotasMora = async (period, fecha_prest, tt_abonos, valor_cuotas, n_cuotas) => {
    const url = process.env.REACT_APP_URL_SERVS+"/getPrestamos.php?modo=getCuotasMora&periodicidad="+period+"&fecha_prest="+fecha_prest+"&tt_abonos="+tt_abonos+"&valor_cuotas="+valor_cuotas+"&n_cuotas="+n_cuotas
    const respuesta = await fetch(url)
    return await respuesta.json();
  }

  getAbonos = async () => {
    let content = []
    const url = process.env.REACT_APP_URL_SERVS+"/getAbonos.php?modo=getAbonosByCed&cedula="+this.glob.getCookie('cedula')
    const respuesta = await fetch(url)
    const data = await respuesta.json()
    if(data.length>0){
      data.forEach(element => {
        content.push(element)
      });
    }
    return content;
  }

  getDatosParaFormulario = async (ced) => {
    const url = process.env.REACT_APP_URL_DATOS_PARA_FORMULARIO+"/llenar_formu_solicitud_dos.php?cedula="+ced
    const respuesta = await fetch(url)
    const data = await respuesta.json()
    return data;
  }

  enviarFormulario = async (datos) => {
    const url = process.env.REACT_APP_URL_SERVS+"/getSolicitudes.php?modo=ingresarSolicitud"
    const respuesta = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(datos),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await respuesta.json()
    return data
  }

}

export default FinancieraService